<template>
  <div id="pagePraca">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg9 sm12 xs12>
          <praca-table></praca-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import PracaTable from "@/components/praca/tablePraca"
export default {
  components: {
    PracaTable
  }
}
</script>
